<template>
  <b-form-group
    label="App ID"
    label-for="app_id"
  >
    <b-form-input
      id="app_id"
      v-model="dataItem.app_id"
      placeholder="App ID"
    />
  </b-form-group>
</template>

<script>
import {
  BFormGroup, BFormInput,
} from 'bootstrap-vue'

export default {
  name: 'AppId',
  components: {
    BFormGroup,
    BFormInput,
  },
  computed: {
    dataItem() {
      return this.$store.getters['customerUsers/dataItem']
    },
  },
}
</script>
