<template>
  <validation-observer ref="simpleRules">
    <b-modal
      v-model="formModal.status"
      title="Firma Kullanıcısı"
      centered
      size="lg"
      no-close-on-backdrop
      no-close-on-esc
    >
      <b-row>
        <b-col cols="12">
          <user-name />
        </b-col>
        <b-col cols="12">
          <user-email />
        </b-col>
        <b-col cols="12">
          <user-status />
        </b-col>
        <b-col cols="12">
          <b-form-checkbox
            v-model="changePassword"
            switch
          >
            Parola Belirle
          </b-form-checkbox>
        </b-col>
        <b-col
          v-if="changePassword"
          cols="12"
        >
          <user-password />
        </b-col>
        <b-col cols="12">
          <b-form-checkbox
            v-model="tokenShow"
            switch
          >
            Proje Entegrasyonu
          </b-form-checkbox>
        </b-col>
        <template v-if="tokenShow">
          <b-col
            cols="12"
            md="6"
          >
            <app-token />
          </b-col>
          <b-col
            cols="12"
            md="6"
          >
            <app-id />
          </b-col>
        </template>
      </b-row>
      <template #modal-footer>
        <div class="w-100 justify-content-between d-flex">
          <b-button
            variant="primary"
            @click="submitForm"
          >
            Kaydet
          </b-button>
          <b-button
            variant="danger"
            @click="closeModal"
          >
            Kapat
          </b-button>
        </div>
      </template>
    </b-modal>
  </validation-observer>
</template>

<script>
import {
  BModal, BRow, BCol, BButton, BFormCheckbox,
} from 'bootstrap-vue'
import UserName from '@/views/Admin/Customers/View/CustomerUsers/elements/UserName.vue'
import UserEmail from '@/views/Admin/Customers/View/CustomerUsers/elements/UserEmail.vue'
import UserPassword from '@/views/Admin/Customers/View/CustomerUsers/elements/UserPassword.vue'
import UserStatus from '@/views/Admin/Customers/View/CustomerUsers/elements/UserStatus.vue'
import AppToken from '@/views/Admin/Customers/View/CustomerUsers/elements/AppToken.vue'
import AppId from '@/views/Admin/Customers/View/CustomerUsers/elements/AppId.vue'
import { ValidationObserver, localize } from 'vee-validate'
import { required } from '@validations'

export default {
  name: 'UserForm',
  components: {
    BModal,
    BRow,
    BCol,
    BButton,
    BFormCheckbox,
    UserName,
    UserEmail,
    UserPassword,
    UserStatus,
    AppToken,
    AppId,
    ValidationObserver,
  },
  data() {
    return {
      changePassword: false,
      tokenShow: false,
      required,
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['customerUsers/dataItem']
    },
    saveData() {
      return this.$store.getters['customerUsers/dataSaveStatus']
    },
    formModal() {
      return this.$store.getters['customerUsers/formModal']
    },
  },
  created() {
    localize('tr')
  },
  methods: {
    closeModal() {
      this.formModal.status = false
    },
    submitForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.submitStatus = true
          this.dataItem.id_customers = this.$route.params.id
          this.$store.dispatch('customerUsers/saveData', this.dataItem)
        }
      })
    },
  },
}
</script>
