<template>
  <div>
    <b-card no-body>
      <b-row>
        <b-col>
          <b-card-header>
            <b-card-title>Firma Kullanıcıları</b-card-title>
          </b-card-header>
        </b-col>
        <b-col
          cols="auto"
          class="mt-1 mr-1"
        >
          <b-button
            variant="primary"
            @click="openModal"
          >
            <FeatherIcon icon="PlusIcon" />
            Oluştur
          </b-button>
        </b-col>
      </b-row>
      <b-table
        responsive="sm"
        :fields="fields"
        :items="dataList"
        striped
        fixed
      >
        <template #cell(status)="data">
          <div :class="data.item.status === '1'? 'text-success' : 'text-danger'">
            <FeatherIcon :icon="data.item.status === '1'? 'CheckCircleIcon' : 'XCircleIcon'" />
          </div>
        </template>
        <template #cell(control)="data">
          <b-button
            variant="flat-primary"
            pill
            class="btn-icon"
            @click="openModal(data.item.id)"
          >
            <FeatherIcon icon="EditIcon" />
          </b-button>
        </template>
      </b-table>
      <b-card-footer>
        <b-pagination
          v-model="currentPage"
          :total-rows="dataCounts"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </b-card-footer>
    </b-card>
    <user-form />
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardHeader,
  BCardTitle,
  BTable,
  BPagination,
  BButton,
  BCardFooter,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import UserForm from '@/views/Admin/Customers/View/CustomerUsers/UserForm.vue'

const tableName = 'customer_users'
export default {
  name: 'Customer',
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BTable,
    BPagination,
    BButton,
    BCardFooter,
    UserForm,
  },
  data() {
    return {
      currentPage: 1,
      perPage: this.$store.state.app.perPage,
      fields: [
        {
          key: 'name',
          label: 'ADI SOYADI',
        },
        {
          key: 'email',
          label: 'E-POSTA',
        },
        {
          key: 'status',
          label: 'DURUM',
          thClass: 'text-nowrap width-100 text-center',
          tdClass: 'text-nowrap text-center',
        },
        {
          key: 'control',
          label: '',
          thClass: 'text-nowrap width-100',
          tdClass: 'text-nowrap',
        },
      ],
      dataQuery: {
        select: [
          `${tableName}.id AS id`,
          `${tableName}.name AS name`,
          `${tableName}.email AS email`,
          `${tableName}.status AS status`,
        ],
        where: {
          'customer_users.id_customers': this.$route.params.id,
        },
        limit: this.$store.state.app.perPage,
        start: 0,
      },
    }
  },
  computed: {
    dataList() {
      return this.$store.getters['customerUsers/dataList']
    },
    dataCounts() {
      return this.$store.getters['customerUsers/dataCounts']
    },
    saveData() {
      return this.$store.getters['customerUsers/dataSaveStatus']
    },
    formModal() {
      return this.$store.getters['customerUsers/formModal']
    },
  },
  watch: {
    currentPage(val) {
      this.dataQuery.start = (val - 1) * this.$store.state.app.perPage
      this.getDataList()
    },
    saveData(val) {
      if (val.status === true) {
        this.showToast({
          variant: 'success', title: 'İşlem Başarılı!', icon: 'CheckIcon', text: val.message,
        })
      } else if (val.status === false) {
        this.showToast({
          variant: 'danger', title: 'İşlem Hatası!', icon: 'XIcon', text: val.message,
        })
      }
      this.getDataList()
    },
  },
  created() {
    this.getDataList()
  },
  methods: {
    openModal(id = null) {
      this.$store.commit('customerUsers/RESET_DATA_ITEM')
      if (id) {
        this.$store.dispatch('customerUsers/getDataItem', id)
      }
      this.formModal.status = true
    },
    getDataList() {
      this.$store.dispatch('customerUsers/getDataList', this.dataQuery)
    },
    removeData(id) {
      this.$swal({
        title: this.$store.state.app.removeTitle,
        text: this.$store.state.app.removeMessage,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$store.state.app.removeConfirm,
        cancelButtonText: this.$store.state.app.removeCancel,
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store.dispatch('customerUsers/removeData', id)
          this.$swal({
            icon: 'success',
            title: this.$store.state.app.removeResultTitle,
            text: this.$store.state.app.removeResultMessage,
            confirmButtonText: this.$store.state.app.removeResultClose,
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    },
    showToast(params) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: params.title,
          icon: params.icon,
          text: params.text,
          variant: params.variant,
        },
      })
    },
  },
}
</script>
